<template>
    <div data-app>
        <v-card class="px-4 h-100">
            <v-card-title class="headline">Tambah Data Chickin</v-card-title>
        
            <perfect-scrollbar
            class="scroll"
            style="max-height: 89%; position: relative;"
            >
                <ValidationObserver ref="form">
                <b-form @submit="onSubmit" v-if="show">
                    <v-card-text>
                        <div class="mb-7" role="group">
                        <label for="input-1">Tanggal Chickin:</label>
                        <b-button id="input-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ form.tgl_chickin | dateIndonesia }}</b-button>
                        <div v-if="errorDate" class="text-danger mt-1"> The Tanggal Setting HE field is required </div>
                        </div>

                        <validationProvider name="Jenis Input" rules="required" v-slot="{ errors, valid }">
                            <b-form-group 
                                id="input-group-4" 
                                label-for="input-4"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                <b-form-radio-group id="input-group-4" v-model="form.newchick" name="kandang-newchick">
                                    <b-form-radio :value='true'>
                                        Kandang Baru
                                    </b-form-radio>
                                    <b-form-radio :value='false'>
                                        Kandang Terisi
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </validationProvider>

                        <validationProvider name="Farm" rules="required" v-slot="{ errors, valid }">
                            <b-form-group
                                id="input-group-6" 
                                label="Farm:" 
                                label-for="input-6"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                <b-form-select2
                                id="input-6"
                                v-model="form.farm"
                                :options="opsFarm"
                                required
                                variant="solid"
                                :state="valid"
                                ></b-form-select2>
                            </b-form-group>
                        </validationProvider>

                        <validationProvider name="Flock" rules="required|alpha_num" v-slot="{ errors, valid }">
                            <b-form-group
                                id="input-group-7" 
                                label="Flock:" 
                                label-for="input-7"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                <b-form-select2
                                v-if="!form.newchick"
                                id="input-7"
                                v-model="form.flock"
                                :options="opsFlock"
                                required
                                variant="solid"
                                :state="valid"
                                ></b-form-select2>
                                <b-form-input
                                v-else
                                id="input-7"
                                class="form-control form-control-solid"
                                v-model="form.flock"
                                required
                                placeholder="Enter Kode FLock"
                                :state="valid"
                                ></b-form-input>
                            </b-form-group>
                        </validationProvider>

                        <validationProvider name="Kandang" rules="required|alpha_num" v-slot="{ errors, valid }">
                            <b-form-group
                                id="input-group-8" 
                                label="Kandang:" 
                                label-for="input-8"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                <b-form-select2
                                v-if="!form.newchick"
                                id="input-8"
                                v-model="form.kandang"
                                :options="opsKandang"
                                required
                                variant="solid"
                                :state="valid"
                                ></b-form-select2>
                                <b-form-input
                                v-else
                                id="input-8"
                                v-model="form.kandang"
                                class="form-control form-control-solid"
                                required
                                placeholder="Enter Kode Kandang"
                                :state="valid"
                                ></b-form-input>
                            </b-form-group>
                        </validationProvider>

                        <b-row v-for="(rinc, index) in form.chickin" :key="index">
                            <b-col cols="10" class="pt-5 pb-0">
                                <h6 class="text-muted">Rinc. Chickin {{index + 1}}</h6>
                            </b-col>
                            <b-col cols="2" class="text-right pt-5 pb-0">
                                <b-button v-show="(form.chickin.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                                <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                                </b-button>
                            </b-col>       
                            <b-col cols="12" sm="6" class="pt-0 pb-0">
                                <validationProvider :name="`Rin ${index+1} Asal Ternak`" rules="required" v-slot="{ errors, valid }">
                                <b-form-group 
                                :id="`input-group-${index}-4`" 
                                label="Asal Ternak:" 
                                :label-for="`input-${index}-4`"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                    <b-form-select2
                                    :id="`input-${index}-4`"
                                    v-model="rinc.asalternak"
                                    :options="opsAsalTernak"
                                    required
                                    variant="solid"
                                    :state="valid"
                                    ></b-form-select2>
                                </b-form-group>
                                </validationProvider>
                            </b-col>     
                            <b-col cols="12" sm="6" class="pt-0 pb-0">
                                <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ errors, valid }">
                                <b-form-group 
                                :id="`input-group-${index}-1`" 
                                label="Sex:" 
                                :label-for="`input-${index}-1`"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                    <b-form-select2
                                    :id="`input-${index}-1`"
                                    v-model="rinc.sex"
                                    :options="opsSex"
                                    required
                                    variant="solid"
                                    :state="valid"
                                    ></b-form-select2>
                                </b-form-group>
                                </validationProvider>
                            </b-col>
                            <b-col cols="12" sm="6" class="pt-0 pb-0">
                                <validationProvider :name="`Rin ${index+1} Strain`" rules="required" v-slot="{ errors, valid }">
                                <b-form-group 
                                :id="`input-group-${index}-2`" 
                                label="Strain:" 
                                :label-for="`input-${index}-2`"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                    <b-form-select2
                                    :id="`input-${index}-2`"
                                    v-model="rinc.strain"
                                    :options="opsStrain"
                                    required
                                    variant="solid"
                                    :state="valid"
                                    ></b-form-select2>
                                </b-form-group>
                                </validationProvider>
                            </b-col>
                            <b-col cols="12" sm="6" class="pt-0 pb-0">
                                <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                                <b-form-group 
                                :id="`input-group-${index}-3`" 
                                label="Jumlah:" 
                                :label-for="`input-${index}-3`"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                    <vue-number-input 
                                    :attrs="{ id: `input-${index}-3`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                                    v-model="rinc.jumlah"
                                    :min="0"
                                    :max="9999999999"
                                    required
                                    center
                                    controls
                                    ></vue-number-input>
                                </b-form-group>
                                </validationProvider>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" class="text-right">
                                <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                                <i class="flaticon2-plus-1 p-0"></i>
                                Add Chickin
                                </b-button>
                            </b-col>
                        </b-row>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <button 
                        @click="onSubmit" 
                        ref="kt_submit_form"
                        class="btn btn-primary font-weight-bold m-3">
                        <i class="la la-save"></i>
                        Tambah
                        </button>

                        <button
                        class="btn btn-clean font-weight-bold m-3"
                        type="button"
                        @click="closeDialog()">
                        <i class="la la-recycle"></i>
                        Batal
                        </button>
                    </v-card-actions>
                
                </b-form>
                </ValidationObserver>
            </perfect-scrollbar>

        </v-card>
    
        <v-dialog
            ref="dialogDate"
            v-model="modalDate"
            persistent
            width="290px"
        >
            <v-date-picker
            v-model="form.tgl_chickin"
            scrollable
            color="bg-primary"
            header-color="bg-primary"
            >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalDate = false"
            >
                OK
            </v-btn>
            </v-date-picker>
        </v-dialog>
        
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

export default {
    name: "unggas-nasional-data-popuasi-form-chickin",
    props: {
        sdata: {
            required: true
        }
    },
    data() {
        return {
            show: true,
            modalDate: false,
            errorDate: false,
            dates: null,
            form: {
                tgl_chickin: new Date().toISOString().slice(0,10),
                newchick: true,
                farm: null,
                flock: null,
                kandang: null,
                chickin: [
                    {
                        asalternak: null,
                        asaldistribusi: null,
                        sex: null,
                        strain: null,
                        jumlah: 0
                    }
                ]
            },
            opsFarm: [{
                value: null,
                text: 'Silahkan pilih Farm',
            }],
            opsFlock: [{
                value: null,
                text: 'Silahkan pilih Flock',
            }],
            opsKandang: [{
                value: null,
                text: 'Silahkan pilih Kandang',
            }],
            opsAsalTernak: [{
                value: null,
                text: 'Silahkan pilih Asal Ternak',
            }],
            opsSex: [{
                value: null,
                text: 'Silahkan pilih Sex',
            }],
            opsStrain: [{
                value: null,
                text: 'Silahkan pilih Strain',
            }],

        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
        populasi () {
            if(!this.form.newchick && this.form.kandang) {
                const kandang = this.opsKandang.find(itm => itm.value == this.form.kandang)
                return kandang._id
            }
            return null
        }
    },
    watch: {
        'form.newchick' (val,old) {
            if (val != old) {
                this.form.farm = null
                this.form.flock = null
                this.form.kandang = null
            }
        },
        'form.farm' (val,old) {
            if (val != old) {
                this.form.flock = null
                this.form.kandang = null
                
                if (!this.form.newchick) {
                    this.getFlock(val)
                }
            }
        },
        'form.flock' (val,old) {
            if (val != old) {
                this.form.kandang = null
                
                if (!this.form.newchick) {
                    this.getKandang(val)
                }
            }
        }
    },
    methods: {
        addRincian(){
            this.form.chickin.push({
                asalternak: null,
                sex: null,
                strain: null,
                jumlah: 0
            })
        },
        deleteRincian(index){
            if (this.form.chickin.length > 1) {
                this.form.chickin.splice(index,1);
            }
            else {
                this.form.chickin = [{
                        asalternak: null,
                        sex: null,
                        strain: null,
                        jumlah: 0
                    }
                ]
            }
        },
        closeDialog() {
            this.onReset()
            return this.$emit('close-modal', {status: true, refresh: false})
        },
        
        getPerusahaan(val) {
            if (!val) {
                return
            }
            PerusahaanService.getPerusahaan({ child: 1, tipe: val })
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsAsalTernak = [{
                        value: null,
                        text: "Silahkan pilih Perusahaan"
                    }]
                    return;
                }

                this.opsAsalTernak = res.data.data
                this.opsAsalTernak.push({
                    value: null,
                    text: "Silahkan pilih Perusahaan"
                })
            })
            .catch((err) => {
                this.opsAsalTernak = [{
                    value: null,
                    text: "Tidak ada Perusahaan"
                }]
                ErrorService.status(err)
            })
        },
        getFarm() {
            PerusahaanService.getPerusahaanFarm()
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsFarm = [{
                        value: null,
                        text: "Silahkan pilih Farm"
                    }]
                    return;
                }

                this.opsFarm = res.data.data
                this.opsFarm.push({
                    value: null,
                    text: "Silahkan pilih Farm"
                })
            })
            .catch((err) => {
                this.opsFarm = [{
                    value: null,
                    text: "Tidak ada Farm"
                }]
                ErrorService.status(err)
            })
        },
        getTernakSex() {
            MasterService.getTernakSex()
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsSex = [{
                        value: null,
                        text: "Silahkan pilih Sex"
                    }]
                    return;
                }

                this.opsSex = res.data.data
                this.opsSex.push({
                    value: null,
                    text: "Silahkan pilih Sex"
                })
            })
            .catch((err) => {
                this.opsSex = [{
                    value: null,
                    text: "Tidak ada Sex"
                }]
                ErrorService.status(err)
            })
        },        
        getTernakStrain() {
            MasterService.getTernakStrain()
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsStrain = [{
                        value: null,
                        text: "Silahkan pilih Strain"
                    }]
                    return;
                }

                this.opsStrain = res.data.data
                this.opsStrain.push({
                    value: null,
                    text: "Silahkan pilih Strain"
                })
            })
            .catch((err) => {
                this.opsStrain = [{
                    value: null,
                    text: "Tidak ada Strain"
                }]
                ErrorService.status(err)
            })
        }, 
        getFlock(val) {
            if (!val) {
                return
            }
            PopulasiService.getFlock({farm: val})
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsFlock = [{
                        value: null,
                        text: "Silahkan pilih Flock"
                    }]
                    return;
                }

                this.opsFlock = res.data.data
                this.opsFlock.push({
                    value: null,
                    text: "Silahkan pilih Flock"
                })
            })
            .catch((err) => {
                ErrorService.status(err)
                this.opsFlock = [{
                    value: null,
                    text: "Tidak ada Flock"
                }]
            })
        }, 
        getKandang(val) {
            if (!val) {
                return
            }
            PopulasiService.getKandang({farm: this.form.farm, flock: val})
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsKandang = [{
                        value: null,
                        text: "Silahkan pilih Kandang"
                    }]
                    return;
                }

                this.opsKandang = res.data.data
                this.opsKandang.push({
                    value: null,
                    text: "Silahkan pilih Kandang"
                })
            })
            .catch((err) => {
                ErrorService.status(err)
                this.opsKandang = [{
                    value: null,
                    text: "Tidak ada Kandang"
                }]
            })
        },
        removeSpinner(button) { 
            button.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        },
        onSubmit(evt) {
            evt.preventDefault()
            this.$refs.form.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }

                if (!this.form.newchick && !this.populasi) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }
                
                // set spinner to submit button
                const submitButton = this.$refs["kt_submit_form"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                // setTimeout(() => {
                    PopulasiService.addChickin({...this.form, populasi: this.populasi})
                        .then((res) => {
                            this.removeSpinner(submitButton)
                            ErrorService.message(res.data)
                            
                            if (res.data.status) {
                                return this.$emit('close-modal', {status: true, refresh: true})
                            }
                            return;
                        })
                        .catch((err) => {
                            this.removeSpinner(submitButton)
                            ErrorService.status(err)
                        })

                // }, 1000);
            })
        },
        onReset () {
            this.form.tgl_chickin = new Date().toISOString().slice(0,10)
            this.form.newchick = true
            this.form.farm = null
            this.form.flock = null
            this.form.kandang = null
            this.form.chickin = [{
                asalternak: null,
                sex: null,
                strain: null,
                jumlah: 0
            }]

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    mounted() {
        if(!this.currentUser.akses) {
            return this.$router.push({ name: 'auth-select-company', query: { rget: this.$route.path }}).catch(()=>{})
        }

        if (this.sdata) {
            this.form.farm = this.sdata._idF,
            this.sdata.rincian.forEach((item, index) => {
                if (index == 0) {
                    this.form.chickin = [{
                        asalternak: this.sdata._idP,
                        asaldistribusi: this.sdata._id,
                        sex: item._idS,
                        strain: item._idSt,
                        jumlah: item.jumlah
                    }]
                } else {
                    this.form.chickin.push({
                        asalternak: this.sdata._idP,
                        asaldistribusi: this.sdata._id,
                        sex: item._idS,
                        strain: item._idSt,
                        jumlah: item.jumlah
                    })
                }   
            })
        }
        this.getPerusahaan(this.currentUser.akses.tipe_up)
        this.getFarm()
        this.getTernakSex()
        this.getTernakStrain()
    },
}
</script>