<template>
    <div data-app>
        <div class="card card-custom h-100">
            <!--begin::Body-->
            <div v-if="!isBusy" class="card-body">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-45 symbol-light mr-5">
                        <span class="symbol-label">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <span class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">Data Distribusi</span>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">{{ items.length }} Pengiriman</span>
                            </div>
                        </div>
                    </div>
                    <!--end::Info-->
                </div>
                
                <div class="separator separator-solid separator-border-1 mt-3"></div>
                <!--end::Top-->
                <perfect-scrollbar
                class="scroll"
                style="max-height: 92%; position: relative;"
                >

                <div class="pt-3 p-0">

                    <!--begin::Item-->
                    <div 
                        v-for="(val, key) in items" 
                        :key="key" 
                        class="d-flex pt-5 px-5 mb-2 bg-hover-light cursor-pointer rounded"
                    >
                        <!--begin::Symbol-->
                        <div @click="openDialogForm('FormChickin', val)" class="symbol d-none d-sm-flex symbol-40 symbol-light-success mr-5 mt-1">
                            <span class="symbol-label">
                            <span class="svg-icon svg-icon-info svg-icon-md">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Box2.svg"/>
                                <!--end::Svg Icon-->
                            </span>         
                            </span>
                        </div>
                        <!--end::Symbol-->

                        <!--begin::Info-->

                        <div @click="openDialogForm('FormChickin', val)" class="d-flex flex-column flex-row-fluid">
                            <!--begin::Info-->
                            <div class="d-flex align-items-center flex-wrap">
                                <span class="text-dark-75 text-hover-primary mb-1 font-size-md font-weight-bolder pr-6">
                                    {{ val.perusahaan }}
                                </span>
                                
                                <div class="d-flex align-items-center">
                                    <span class="svg-icon svg-icon-md svg-icon-primary pr-1 pb-1">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="text-muted font-weight-normal font-size-sm pr-6">{{ val.tgl_distribusi | dateIndonesia }}</span>
                                </div>
                            </div>

                            <div class="d-flex align-items-center flex-wrap">
                                <span class="text-primary font-weight-bold mr-2">
                                    Strain:
                                </span>
                                <b-badge class="mx-1" v-for="(res, ids) in viewStrain(val.rincian)" :key="ids" variant="success">{{ res }}</b-badge>
                            </div>

                            <b-row class="mt-0 w-100">
                                <b-col v-for="(rinc, ids) in val.rincian" :key="ids" cols="12" sm="6" class="py-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        <span class="text-primary font-weight-bold">
                                            {{ rinc.sex }}
                                        </span>
                                        <span class="mb-1 text-muted font-weight-bold">
                                            {{ rinc.jumlah | nominal }} ekor
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>

                            <div class="d-flex align-items-center flex-wrap mt-2">
                                <span class="text-primary font-weight-bold mr-2">
                                    Sisa DOC:
                                </span>
                                <span class="text-danger">{{ (val.rincian_sum_jumlah - val.tujuan_sum_jumlah) | nominal }} ekor</span>
                            </div>

                            <!--end::Info-->
                            <div class="separator separator-dashed separator-border-2 mt-1 mb-2"></div>

                        </div>
                        <!--end::Info-->

                        <span 
                        v-b-tooltip.hover title="Selesai"
                        @click="openDialogSelesai(val)"
                        class="svg-icon p-2 m-0 svg-icon-info svg-icon-xl">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Code/Done-circle.svg"/>
                            <!--end::Svg Icon-->
                        </span>

                    </div>
                    <!--end::Item-->
                </div>
                <!--end::Bottom-->
                </perfect-scrollbar>
            </div>            
            <div v-else class="card-body">
                <FacebookLoader></FacebookLoader>
            </div>
            <!--end::Body-->

            <div class="card-footer py-3">
                <button
                class="btn btn-size-sm btn-clean font-weight-bold m-3"
                type="button"
                @click="closeDialog()">
                    <i class="la la-recycle"></i>
                    Close
                </button>
            </div>
        </div>

        <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
            <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
        </v-dialog>

        <v-dialog
        v-model="dialogForm"
        max-width="300">
            <v-card>
                <v-card-title class="headline">Tambah data</v-card-title>

                <v-card-text>
                    <span>Apakah anda yakin?</span>
                    <b-form-textarea
                    id="note"
                    class="mt-2"
                    v-model="form.note"
                    placeholder="Silahkan beri catatan..."
                    rows="2"
                    max-rows="4"
                    ></b-form-textarea>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <b-button
                variant="link-success"
                class="m-3"
                @click="closeDialogSelesai">
                    BATAL
                </b-button>

                <b-button
                variant="link-danger"
                class="m-3"
                @click="distribusiSelesai">
                    LANJUTKAN
                </b-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ErrorService from "@/core/services/error.service";
import FormChickin from "@/view/pages/nasional/populasi/form/_Chickin.vue";

export default {
    name: "unggas-nasional-data-popuasi-form-asal-distribusi",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        FacebookLoader,
        FormChickin
    },
    data() {
        return {
            isBusy: true,
            items: null,
            sdataForm: null,
            curComponent: null,
            dialogDetail: false,
            dialogForm: false,
            form: {
                distribusi: null,
                note: null
            }
        }
    },
    methods: {
        viewStrain(val) {
            return val.map(e => e.strain).filter((value, index, self) => self.indexOf(value) === index)
        },
        closeDialog() {
            return this.$emit('close-modal', true)
        },
        openDialogForm(name, data) {
            this.curComponent = name
            this.sdataForm = data
            this.dialogDetail = true
        },
        closeDialogForm(res) {
            if (res.status) {
                this.curComponent = null
                this.sdataForm = null
                this.dialogDetail = false
                if (res.refresh) {
                    this.isBusy = true
                    this.getData()
                }
            }
        },    
        getData () {
            PopulasiService.getDistribusiList()
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.items = []
                    this.isBusy = false
                    return;
                }
                this.items = res.data.data
                this.isBusy = false
            })
            .catch((err) => {
            this.items = []
            this.isBusy = false
            return ErrorService.status(err)
            })
        },

        openDialogSelesai(val) {
            this.form.distribusi = val._id
            this.form.note = null
            this.dialogForm = true
        },
        closeDialogSelesai() {
            this.form.distribusi = null
            this.form.note = null
            this.dialogForm = false
        },

        distribusiSelesai() {
            PopulasiService.updateChickinFromDistribusi(this.form)
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    return;
                }
                this.closeDialogSelesai()
                this.getData()
            })
            .catch((err) => {
                return ErrorService.status(err)
            })
        }
    },
    mounted() {
        this.getData()
    },
}
</script>